
import axios from "axios";
import { meta } from "@/utils";
export default {
  name: "IndexPage",
  head() {
    return {
      title: `All tours and travel tips and information - ${this.$pageTitle}`,
      meta: meta(),
    };
  },
  async asyncData(context) {
    try {
      const { store, error } = context;
      const { apiUrl } = store.getters;
      let res = await axios.get(`${apiUrl}/fetch/posts/2`);
      if (res.data) {
        const { posts } = res.data;
        return { posts };
      } else {
        error({ statusCode: 404, message: "Not found" });
      }
    } catch (err) {
      const { error } = context;
      error({ statusCode: 500, message: "Something wrong. Please try again" });
    }
  },
  data() {
    return { posts: [] };
  },
};
